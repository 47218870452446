import { assignStringValue } from '../../utils/assignValue';
import { DD365InventoryItem } from '../../webservices/dd365/models/DD365InventoryResponse';
import DD365VinDetailsResponse from '../../webservices/dd365/models/DD365VinDetailsResponse';
import { EFCVinResponse } from '../../webservices/efc/models/EFCVinResponse';

class VinItem {
  vin = '';

  accessories = '';

  accessoriesList: string[] = [];

  dealerCode = '';

  originalDealerCode = '';

  invoiceDate = '';

  dealerInvoice = '';

  msrp = '';

  msrpDiscount = '';

  port = '';

  status = '';

  tdaFee = '';

  dph = '';

  stopSale = '';

  modelCode = '';

  financeReserve = '';

  holdback = '';

  baseMsrp = '';

  isTempVin = false;

  setFromEFC = (vinResp: EFCVinResponse) => {
    this.vin = vinResp.vin;
    this.modelCode = vinResp.modelCode;
    this.accessories = vinResp.accessories.map(acc => acc.code).join(', ');
    this.accessoriesList = vinResp.accessories.map(acc => `${acc.title} (${acc.code})`);
    this.dealerCode = assignStringValue(vinResp.currentDealerCode);
    this.originalDealerCode = vinResp.wholesaleDealerCodes ? vinResp.wholesaleDealerCodes[0] : '';
    this.invoiceDate = vinResp.dealerPricing.invoiceDate;
    this.dealerInvoice = vinResp.dealerPricing.dealercostperinvoice;
    this.msrp = vinResp.dealerPricing.totalvehmsrp;
    this.msrpDiscount = vinResp.dealerPricing.discountAmt;
    this.port = vinResp.port;
    this.status = vinResp.vehicleCategory;
    this.tdaFee = vinResp.dealerPricing.tda;
    this.dph = vinResp.vehicleDestinationCharge;
    this.financeReserve = vinResp.dealerPricing.financialreserve;
    this.holdback = vinResp.dealerPricing.holdback;
    this.baseMsrp = vinResp.dealerPricing.vehiclemsrp;
  };

  setFromDD365Inventory = (res: DD365InventoryItem) => {
    this.vin = res.vin;
    this.modelCode = res.model.modelCd;
    this.dealerCode = res.dealerCd;
    this.invoiceDate = res.invoiceDate;
    this.dealerInvoice = assignStringValue(res.price.totalDealerInvoice);
    this.msrp = assignStringValue(res.price.totalMsrp);
    this.msrpDiscount = '';
    this.port = '';
    this.status = res.category;
    this.dph = assignStringValue(res.price.dph);
    this.financeReserve = '0';
    this.holdback = '0';
    this.baseMsrp = assignStringValue(res.price.totalMsrp);
    this.isTempVin = res.isTempVin;

    this.tdaFee = ''; // removed for SET
    this.originalDealerCode = ''; // removed for SET
  };

  setAccessoriesFrom365 = (config: string, configList: string[]) => {
    this.accessories = config;
    this.accessoriesList = configList;
  };

  setFromDD365 = (res: DD365VinDetailsResponse) => {
    this.vin = res.vin;
    this.modelCode = res.model.code;
    this.accessories = res.options.map(opt => opt.code).join(', ');
    this.accessoriesList = res.options.map(opt => `${opt.marketingName} (${opt.code})`);
    this.dealerCode = res.dealerCode;
    this.originalDealerCode = res.dealerCode;
    this.invoiceDate = res.invoiceDate;
    this.dealerInvoice = assignStringValue(res.price.totalDealerInvoice);
    this.msrp = assignStringValue(res.price.totalMsrp);
    this.msrpDiscount = '';
    this.port = '';
    this.status = res.category;
    this.tdaFee = assignStringValue(res.price.tda);
    this.dph = assignStringValue(res.price.dph);
    this.financeReserve = '0';
    this.holdback = '0';
    this.baseMsrp = assignStringValue(res.price.totalMsrp);
  };

  setStopSale = (value: string) => {
    this.stopSale = value;
  };
}

export default VinItem;
