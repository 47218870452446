import React, { ReactNode } from 'react';
import Button from '../../../components/Button';
import AdInput from '../../components/AdInput';
import styles from './styles.module.scss';

interface Props {
  vin: string;
  children?: ReactNode;
  onVinClick: () => void;
  onSubmit: () => void;
}

const FinalSelectionFlow = ({ vin, children, onVinClick, onSubmit }: Props) => {
  return (
    <>
      <div className={styles.finalSelection}>
        <div>
          <AdInput
            className={styles.vinInputContainer}
            id="vin-input"
            label="VIN"
            type="text"
            buttonClickHandler={onVinClick}
            inputClickHandler={onVinClick}
            onChangeHandler={() => {}}
            value={vin}
            icon="edit"
          />
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        <Button id="create-offer-cta" variant="primary" onClick={onSubmit}>
          Create Offer
        </Button>
      </div>
    </>
  );
};

export default FinalSelectionFlow;
